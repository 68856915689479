import React, { useState } from "react";
import { InputSearch } from "../../common/components/modals/InputSearch";
import { Overlay } from "../../common/components/modals/Overlay";
import {
	criteriaGroupsType,
	notDeletableType,
	searchTitleType,
} from "../../common/types";
import { getCriteriaToFilter, visibleOnUserInput } from "../utils";
import { AdvancedSearchFooter } from "./AdvancedSearchFooter";
import { AdvancedSearchModalGroup } from "./AdvancedSearchModalGroup";

export function AdvancedSearchModal({
	criteria,
	notDeletable,
	searchTitle,
	...props
}) {
	const criteriaToFilter = getCriteriaToFilter(criteria).flat();
	const [visibleCriteria, setVisibleCriteria] = useState(criteriaToFilter);
	const hasVisibleCriteria = (group) =>
		Object.keys(group.criteria).some((key) =>
			visibleCriteria.some((item) => item.key === key),
		);

	const handleSearchInputChange = (input) => {
		const visible = criteriaToFilter.filter((item) =>
			visibleOnUserInput(item.label, input),
		);
		setVisibleCriteria(visible);
	};

	const title = "Advanced " + searchTitle;

	return (
		<Overlay
			title={title}
			additionalClasses="advanced-search-overlay"
			searchInput={InputSearch.add(
				"search-criteria-filter",
				"Search option name",
				"Enter search option name",
				"No search criteria found.",
				visibleCriteria.length,
				handleSearchInputChange,
			)}
		>
			<div className="search-criteria modal__content">
				{criteria.map(
					(group) =>
						hasVisibleCriteria(group) && (
							<AdvancedSearchModalGroup
								key={group.name}
								group={group}
								notDeletable={notDeletable}
								visibleCriteria={visibleCriteria}
							/>
						),
				)}
			</div>

			<AdvancedSearchFooter {...props} />
		</Overlay>
	);
}

AdvancedSearchModal.propTypes = {
	criteria: criteriaGroupsType.isRequired,
	notDeletable: notDeletableType,
	searchTitle: searchTitleType.isRequired,
};
