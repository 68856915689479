import React, { useState } from "react";
import { Modal } from "../../../common/components/modals/Modal";
import { ModalFooter } from "../../../common/components/modals/ModalFooter";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import useSearchState from "../../useSearchState";
import { createView } from "../api";
import useColumnsState from "../edit/useColumnsState";
import useView from "../useView";
import { ViewNameInput } from "../ViewNameInput";

export function SaveView({ namespace }) {
	const { columns } = useColumnsState();
	const { onSaveViewAs } = useView();
	const { columnsVisible } = useSearchState();
	const { onCloseModal, clearModals } = useModal();

	const [viewState, setViewState] = useState({
		input: "",
		error: undefined,
		loading: false,
	});

	const handleInputChange = (e) => {
		if (viewState.loading) {
			return;
		}

		const input = e.target.value;
		setViewState((viewState) => ({
			...viewState,
			input,
			error: input ? undefined : "Name is required.",
		}));
	};

	const save = () => {
		const successHandler = (view) => {
			setViewState((viewState) => ({
				...viewState,
				loading: false,
			}));
			onSaveViewAs(view);
			clearModals();
		};
		const failureHandler = (error) => {
			setViewState((viewState) => ({
				...viewState,
				error: error.message,
				loading: false,
			}));
		};

		const columnsToSave = columnsVisible.filter((item) => {
			const column = columns.find(
				(column) => column.key === item.get("key"),
			);
			return column.render !== null;
		});

		createView(
			viewState.input,
			namespace,
			columnsToSave,
			successHandler,
			failureHandler,
		);
	};

	const handleSubmit = () => {
		if (!viewState.input) {
			setViewState((viewState) => ({
				...viewState,
				error: "Name is required.",
			}));
			return;
		}

		setViewState((viewState) => ({
			...viewState,
			error: undefined,
			loading: true,
		}));
		save();
	};

	const title = "Save View";

	return (
		<Modal title={title}>
			<div className="modal__content">
				<div className="modal__body">
					<ViewNameInput
						error={viewState.error}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<ModalFooter>
				<button
					type="button"
					className="btn btn--secondary"
					onClick={() => onCloseModal()}
				>
					Cancel
				</button>
				<button
					type="button"
					className="btn btn--primary"
					onClick={handleSubmit}
					disabled={viewState.loading}
				>
					{viewState.loading ? "Saving..." : "Save"}
				</button>
			</ModalFooter>
		</Modal>
	);
}

SaveView.propTypes = {
	namespace: namespaceType.isRequired,
};
