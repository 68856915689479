import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import { useDropdown } from "../../../../common/components/dropdown/useDropdown";
import useModal from "../../../../common/components/modals/useModal";
import useFeatureFlags from "../../../../common/feature-flags/useFeatureFlags";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import {
	requiredFeatureFlagType,
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { getCriterionModalId } from "../../utils";
import { CriterionLabel } from "../common/CriterionLabel";
import { SearchCriterion } from "../common/SearchCriterion";
import useDropdownPosition from "../useDropdownPosition";
import RadioButtons from "./RadioButtons";

export function RadioButtonCriterion({
	choices,
	criterionKey,
	closeOnClickOutside,
	label,
	prefix,
	quickSearch,
	requiredFeatureFlag,
	requiredRoles,
	visibilitySelector,
}) {
	const { currentInput, onInputChange } = useFormComponentState(criterionKey);
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } =
		useDropdown(closeOnClickOutside);
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
	);
	const { hasFeatureFlagEnabled } = useFeatureFlags(requiredFeatureFlag);
	const modalId = getCriterionModalId(criterionKey);

	const handleDropdownToggleClick = () => {
		onDropdownToggle();
		onOpenModal(modalId);
	};

	const handleSaveInput = (input) => {
		input && onInputChange(input);
		onCloseModal(modalId);
	};

	return (
		<SearchCriterion
			label={label}
			requiredFeatureFlag={requiredFeatureFlag}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<div
				ref={dropdownRef}
				className="criterion dropdown radio-dropdown"
			>
				<button
					onClick={handleDropdownToggleClick}
					type="button"
					className="dropdown-toggle choices-visible"
					aria-expanded={dropdownIsOpened}
				>
					<CriterionLabel label={label} prefix={prefix} />
				</button>

				<span className="dropdown-choices-counter">1</span>

				<ConditionalWrapper
					condition={isMobile}
					wrapper={(children) => {
						return (
							<ActionsMenu
								id={modalId}
								title={label}
								onClose={handleSaveInput}
							>
								{children}
							</ActionsMenu>
						);
					}}
				>
					<div
						className={clsx("dropdown-menu", {
							"dropdown-menu--opened": dropdownIsOpened,
							"dropdown-menu--inverted-y":
								isDropdownBelowViewport,
							"dropdown-menu--hidden": isDropdownHidden,
						})}
					>
						<div className="dropdown-items-wrapper">
							<RadioButtons
								label={label}
								choices={choices}
								currentInput={
									hasFeatureFlagEnabled ? currentInput : ""
								}
								criterionKey={criterionKey}
								onInputChange={handleSaveInput}
							/>
						</div>
					</div>
				</ConditionalWrapper>
			</div>
		</SearchCriterion>
	);
}

RadioButtonCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	requiredFeatureFlag: requiredFeatureFlagType,
	requiredRoles: requiredRolesType,
	choices: PropTypes.array.isRequired,
	prefix: PropTypes.string,
	visibilitySelector: visibilitySelectorType,
	closeOnClickOutside: PropTypes.bool,
	quickSearch: PropTypes.bool,
};
