import PropTypes from "prop-types";
import React from "react";
import useFeatureFlags from "../../../../common/feature-flags/useFeatureFlags";
import useVisibilityState from "../../../../common/forms/useVisibilityState";
import useRolesRestrictions from "../../../../common/roles/useRolesRestrictions";
import useRolesVisibility from "../../../../common/roles/useRolesVisibility";
import {
	childrenType,
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { RestrictedCriterion } from "../RestrictedCriterion";

export function SearchCriterion({
	children,
	label,
	requiredFeatureFlag,
	requiredRoles,
	visibilitySelector,
}) {
	const visible = useVisibilityState(visibilitySelector);
	const { restrictOnRoles } = useRolesRestrictions(requiredRoles);
	const { hasRequiredRoles } = useRolesVisibility(requiredRoles);
	const { hasFeatureFlagEnabled } = useFeatureFlags(requiredFeatureFlag);

	if (!visible || !hasRequiredRoles || !hasFeatureFlagEnabled) {
		return null;
	}

	if (restrictOnRoles.length > 0) {
		return <RestrictedCriterion label={label} roles={restrictOnRoles} />;
	}

	return children;
}

SearchCriterion.propTypes = {
	children: childrenType.isRequired,
	label: PropTypes.string.isRequired,
	requiredRoles: requiredRolesType,
	requiredFeatureFlag: PropTypes.string,
	visibilitySelector: visibilitySelectorType,
};
