import PropTypes from "prop-types";
import React from "react";
import { ActionsMenuButton } from "../../search/actions/ActionsMenuButton";
import { Logo } from "./Logo";
import useModal from "./modals/useModal";

export function ActionsMenu({
	children,
	id,
	onClose = () => {},
	showBackButton = false,
	title = "Actions",
}) {
	const { openedModals, onCloseModal } = useModal();
	const menuIsOpened = openedModals.includes(id);

	if (!menuIsOpened) {
		return null;
	}

	const handleCloseModal = () => onClose() || onCloseModal(id);

	return (
		<div className="overlay-menu" aria-expanded={menuIsOpened}>
			<header>
				<Logo />
				{showBackButton && (
					<ActionsMenuButton
						onClick={() => onCloseModal()}
						name="Back"
						type="btn--back"
					/>
				)}
				<p className="title">{title}</p>
				<button
					type="button"
					className="btn btn--close"
					onClick={handleCloseModal}
				>
					Close {title} menu
				</button>
			</header>

			{children}
		</div>
	);
}

ActionsMenu.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	showBackButton: PropTypes.bool,
	title: PropTypes.string,
};
