import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../common/components/ConditionalWrapper";
import { useDropdown } from "../../common/components/dropdown/useDropdown";
import { MENU_DOWNLOADS_OPTIONS } from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";
import { useAccessWindowSize } from "../../common/layout/useAccessWindowSize";
import { downloadFormatsType } from "../../common/types";
import { DownloadLink } from "./DownloadLink";

export function DownloadDropdown({
	formats,
	hasRequiredRoles,
	getDownloadUrl,
	onDropdownItemClick,
}) {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal } = useModal();

	const handleDropdownToggleClick = () => {
		onDropdownToggle();
		onOpenModal(MENU_DOWNLOADS_OPTIONS);
	};

	const handleDropdownItemClick = (id) => {
		onDropdownToggle();
		onDropdownItemClick(id);
	};

	const items = formats.map((format, index) => {
		const { id, label } = format;

		const item = hasRequiredRoles ? (
			<DownloadLink
				format={format}
				label={label}
				getDownloadUrl={getDownloadUrl}
				onLinkClick={onDropdownToggle}
				classes={"dropdown-item"}
			/>
		) : (
			<button
				className="dropdown-item"
				onClick={() => handleDropdownItemClick(id)}
			>
				{label}
			</button>
		);

		return <li key={index}>{item}</li>;
	});

	return (
		<div ref={dropdownRef} className="dropdown dropdown--secondary">
			<button
				className="dropdown-toggle btn--has-icon btn--export"
				aria-expanded={dropdownIsOpened}
				onClick={handleDropdownToggleClick}
			>
				<span>Export as...</span>
			</button>

			<ConditionalWrapper
				condition={isMobile}
				wrapper={(children) => (
					<ActionsMenu
						id={MENU_DOWNLOADS_OPTIONS}
						showBackButton
						title="Export as..."
					>
						{children}
					</ActionsMenu>
				)}
			>
				<div
					className={clsx("dropdown-menu", {
						"dropdown-menu--opened": dropdownIsOpened,
					})}
				>
					<ul className="dropdown-menu-items">{items}</ul>
				</div>
			</ConditionalWrapper>
		</div>
	);
}

DownloadDropdown.propTypes = {
	formats: downloadFormatsType.isRequired,
	hasRequiredRoles: PropTypes.bool,
	getDownloadUrl: PropTypes.func.isRequired,
	onDropdownItemClick: PropTypes.func.isRequired,
};
