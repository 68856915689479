import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import {
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import useValidation from "../../validation/useValidation";
import { SearchCriterion } from "../common/SearchCriterion";
import { DateComponent } from "./DateComponent";

export const DateCriterion = ({
	criterionKey,
	label,
	requiredRoles,
	visibilitySelector,
	...props
}) => {
	const { currentInput, onInputChange, onClearInput } = useFormComponentState(
		criterionKey,
		"",
	);
	const { valid } = useValidation(criterionKey);

	return (
		<SearchCriterion
			label={label}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<DateComponent
				criterionKey={criterionKey}
				currentInput={currentInput}
				label={label}
				onClearInput={onClearInput}
				onInputChange={onInputChange}
				valid={valid}
				{...props}
			/>
		</SearchCriterion>
	);
};

DateCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	requiredRoles: requiredRolesType,
	visibilitySelector: visibilitySelectorType,
};
