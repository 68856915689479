import React from "react";
import { MODAL_LOAD_VIEW } from "../../../common/components/modals/constants";
import { Modal } from "../../../common/components/modals/Modal";
import { ModalFooter } from "../../../common/components/modals/ModalFooter";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import useView from "../useView";

export function DeleteView({ namespace }) {
	const { onDeleteView, viewToDelete } = useView(namespace);
	const { onOpenModal } = useModal();

	const title = "Are you sure you want to delete this view?";
	const alignContent = "modal__container--center";

	const handleDeleteClick = () => {
		onDeleteView(viewToDelete);
		onOpenModal(MODAL_LOAD_VIEW);
	};

	const handleCancelClick = () => {
		onOpenModal(MODAL_LOAD_VIEW);
	};

	return (
		<Modal title={title} alignContent={alignContent}>
			<div className="flex justify-center modal__content">
				<p>You can’t undo this step.</p>
			</div>

			<ModalFooter classes="justify-space-between">
				<button
					type="button"
					className="btn btn--secondary"
					onClick={handleDeleteClick}
				>
					Yes, delete
				</button>
				<button
					type="button"
					className="btn btn--primary"
					onClick={handleCancelClick}
				>
					No, leave
				</button>
			</ModalFooter>
		</Modal>
	);
}

DeleteView.propTypes = {
	namespace: namespaceType.isRequired,
};
