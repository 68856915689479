import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { sendForm } from "../api/sendForm";
import useFetchData from "../api/useFetchData";
import { MODAL_REQUEST_SUCCESS } from "../components/modals/constants";
import { Modal } from "../components/modals/Modal";
import useModal from "../components/modals/useModal";
import { DropdownField } from "../forms/components/DropdownField";
import { EmailField } from "../forms/components/EmailField";
import { PhoneField } from "../forms/components/PhoneField";
import { TextareaField } from "../forms/components/TextareaField";
import { TextField } from "../forms/components/TextField";
import { namespaceType } from "../types";
import useFetchUserData from "../user/useFetchUserData";

export function DataServicesRequestModal({ namespace }) {
	const { onOpenModal, onCloseModal } = useModal();
	const {
		data: countries,
		loading: fetching,
		error: fetchError,
	} = useFetchData({
		endpoint: "/api/v1/choices/country",
	});
	const { userData, loading } = useFetchUserData();

	const defaultValues = userData
		? {
				first_name: userData.firstName,
				last_name: userData.lastName,
				email: userData.email,
				company: userData.company,
				job_title: userData.jobTitle,
			}
		: {};

	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({ defaultValues });
	const [error, setError] = useState(undefined);
	const errorsCount = Object.keys(errors).length;

	useEffect(() => {
		reset(defaultValues);
	}, [userData]);

	useEffect(() => {
		errorsCount > 0
			? setError("Please fill all the required fields.")
			: null;
	}, [errorsCount]);

	const onSubmit = (data) => {
		const successHandler = () => {
			onOpenModal(MODAL_REQUEST_SUCCESS);
			setError(undefined);
		};

		const failureHandler = (error) => {
			setError(error.message);
		};

		sendForm(
			data,
			"/api/v1/data_services_requests",
			successHandler,
			failureHandler,
		);
	};

	const extractType = [
		{ key: "aircraft", value: "Aircraft" },
		{ key: "operators", value: "Operators" },
		{ key: "airports", value: "Airports" },
		{ key: "capacity", value: "Capacity" },
		{ key: "contacts", value: "Contacts" },
		{ key: "routes", value: "Routes" },
		{ key: "schedules", value: "Schedules" },
		{ key: "other", value: "Other" },
	];

	const extractTypePreselected =
		extractType.find((type) => type.key === namespace) || {};

	const extractFrequency = [
		{ key: "one-time", value: "One-time" },
		{ key: "quarterly", value: "Quarterly" },
		{ key: "monthly", value: "Monthly" },
		{ key: "weekly", value: "Weekly" },
	];

	const title = "Data Services Request";
	const additionalClasses = "modal--relative modal--wide";

	return (
		<Modal title={title} additionalClasses={additionalClasses}>
			<div className="modal__content">
				<p>
					ch-aviation can provide you with customized raw data
					extracts on a one-time, weekly or monthly basis as required.
					With tens of thousands of records in our database that
					includes up to date fleet list information for over 500
					airlines as well as airport, route and schedule data, we can
					provide you with the data you want, in the format and at the
					frequency you prefer, all based on your specific request. To
					get a quote and data sample based on your individual
					requirements, fill out the data services request below and
					one of our representatives will be in touch with you
					shortly.
				</p>

				<div className="modal__body">
					<form
						className="grid justify-space-between"
						id="data-services-request-form"
						onSubmit={handleSubmit(onSubmit)}
						aria-label={title}
					>
						<fieldset className="form-section">
							<legend className="form-section-title">
								Personal Information
							</legend>

							<section className="section-columns grid justify-space-between">
								<TextField
									name="first_name"
									id="data-services-request-first-name"
									label="First Name"
									placeholder={
										loading ? "Loading..." : "First Name"
									}
									required
									register={register}
									errors={errors}
								/>

								<TextField
									name="last_name"
									id="data-services-request-last-name"
									label="Last Name"
									placeholder={
										loading ? "Loading..." : "Last Name"
									}
									required
									register={register}
									errors={errors}
								/>

								<EmailField
									name="email"
									id="data-services-request-email"
									label="Email"
									placeholder={
										loading ? "Loading..." : "Your Email"
									}
									required
									register={register}
									errors={errors}
								/>

								<PhoneField
									name="phone"
									id="data-services-request-phone"
									label="Phone"
									placeholder="Phone Number"
									required
									register={register}
									errors={errors}
								/>
							</section>
						</fieldset>

						<fieldset className="form-section">
							<legend className="form-section-title">
								Company Information
							</legend>

							<section className="section-columns grid justify-space-between">
								<TextField
									name="company"
									id="data-services-request-company"
									label="Company"
									placeholder={
										loading ? "Loading..." : "Company name"
									}
									required={false}
									autoComplete="organization"
									register={register}
									errors={errors}
								/>

								<TextField
									name="job_title"
									id="data-services-request-job-title"
									label="Job Title"
									placeholder={
										loading ? "Loading..." : "Job Title"
									}
									required={false}
									autoComplete="organization"
									register={register}
									errors={errors}
								/>

								<TextField
									name="address_1"
									id="data-services-request-address-1"
									label="Address 1"
									placeholder="Address"
									required
									autoComplete="address-line1"
									register={register}
									errors={errors}
								/>

								<TextField
									name="address_2"
									id="data-services-request-address-2"
									label="Address 2"
									placeholder="Address"
									required={false}
									autoComplete="address-line2"
									register={register}
									errors={errors}
								/>

								<fieldset className="zip-code form-group">
									<TextField
										name="city"
										id="data-services-request-city"
										label="City"
										placeholder="City"
										required
										autoComplete="address-level2"
										register={register}
										errors={errors}
									/>

									<TextField
										name="postal_code"
										id="data-services-request-postal-code"
										label="ZIP Code"
										placeholder="ZIP Code"
										required={false}
										autoComplete="postal-code"
										classes="postal-code"
										register={register}
										errors={errors}
									/>
								</fieldset>

								<div className="form-group">
									<Controller
										control={control}
										name="country"
										rules={{ required: true }}
										render={({
											field: { value, onChange },
											fieldState: { error },
										}) => (
											<DropdownField
												name="Country"
												dropdownToggle={
													value
														? value
														: fetching
															? "Loading..."
															: "Select your country"
												}
												dropdownItems={
													!fetching && !fetchError
														? countries
														: []
												}
												required
												onDropdownItemClick={onChange}
												error={error}
											/>
										)}
									/>
								</div>

								<div className="form-group">
									<Controller
										control={control}
										name="extract_type"
										rules={{ required: true }}
										defaultValue={
											extractTypePreselected.value || ""
										}
										render={({
											field: { value, onChange },
											fieldState: { error },
										}) => (
											<DropdownField
												name="Extract Type"
												dropdownToggle={
													value
														? value
														: "Select extract type"
												}
												dropdownItems={extractType}
												required
												onDropdownItemClick={onChange}
												selected={
													Object.keys(
														extractTypePreselected,
													).length > 0
												}
												error={error}
											/>
										)}
									/>
								</div>

								<div className="form-group">
									<Controller
										control={control}
										name="extract_frequency"
										rules={{ required: true }}
										defaultValue="One-time"
										render={({
											field: { value, onChange },
											fieldState: { error },
										}) => (
											<DropdownField
												name="Extract Frequency"
												dropdownToggle={
													value
														? value
														: "Select extract frequency"
												}
												dropdownItems={extractFrequency}
												required
												onDropdownItemClick={onChange}
												selected
												error={error}
											/>
										)}
									/>
								</div>

								<TextareaField
									id="extra_scope"
									name="extra_scope"
									label="Extract Scope"
									placeholder="What will be extract scope?"
									required
									register={register}
									errors={errors}
								/>

								<TextareaField
									id="planned_use"
									name="planned_use"
									label="Planned Use"
									placeholder="How you plan to use it?"
									required
									register={register}
									errors={errors}
								/>
							</section>
						</fieldset>
					</form>
				</div>
			</div>

			<footer className={clsx(`flex modal__actions`, { error })}>
				{error && (
					<span className="error-text" role="alert">
						{error}
					</span>
				)}

				<button
					type="button"
					className="btn btn--secondary"
					onClick={() => onCloseModal()}
				>
					Cancel
				</button>
				<button
					type="submit"
					className="btn btn--primary"
					form="data-services-request-form"
				>
					Send request
				</button>
			</footer>
		</Modal>
	);
}

DataServicesRequestModal.propTypes = {
	namespace: namespaceType.isRequired,
};
