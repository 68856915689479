import PropTypes from "prop-types";
import React from "react";
import { rolePackageNames } from "../../../common/roles/messages";

export function RestrictedCriterion({ label, roles }) {
	const packages = roles.map((role, index) => {
		if (index > 0) {
			return ` or ${rolePackageNames[role]}`;
		}

		return rolePackageNames[role];
	});

	return (
		<div className="criterion criterion-restricted">
			<section className="criterion-restricted__content">
				<p className="title">{label}</p>
				<p>Requires {packages} subscription.</p>
				<a href="/account-management/subscription">
					Add to Subscription
				</a>
			</section>
		</div>
	);
}

RestrictedCriterion.propTypes = {
	label: PropTypes.string.isRequired,
	roles: PropTypes.array.isRequired,
};
