import Immutable, { OrderedMap } from "immutable";

export const columnsByGroups = (columns) => {
	const groups = columns.reduce((columnGroup, column) => {
		if (column.group) {
			const { id } = column.group;
			columnGroup[id] = columnGroup[id] ?? [];
			columnGroup[id].push(column);
		}

		return columnGroup;
	}, {});

	return Object.values(groups);
};

export const filterColumnsToRender = (columns, input) => {
	return columns.filter((column) => {
		let visible = true;
		const visibilitySelector = column.visibilitySelector;

		if (visibilitySelector) {
			const { criterionKey, visibleOn } = visibilitySelector;
			visible = visibleOn.some((key) => input.get(criterionKey) === key);
		}

		return visible && column.render !== null;
	});
};

export const getColumnsKeys = (columns) => {
	return columns
		.filter((column) => column.group)
		.map((column) => column.key)
		.join(",");
};

export const getOrderedColumns = (columns) => {
	return OrderedMap(columns.map((c) => [c.key, c]));
};

export const getColumnsRenderers = (columns) => {
	return {
		...columns.reduce((obj, item) => {
			obj[item.key] = item.render;
			return obj;
		}, {}),
	};
};

export const getMandatoryColumns = (columns, input) => {
	return columns
		.filter((column) => {
			const mandatoryFor = column.mandatoryFor;

			if (!mandatoryFor) {
				return false;
			}

			const { criterionKey, values } = mandatoryFor;

			return values.some((key) => input.get(criterionKey) === key);
		})
		.map((column) => column.key);
};

export const getColumnsToRemoveFromInput = (columns, input) => {
	return columns
		.filter((column) => {
			const { mandatoryFor, visibilitySelector } = column;

			if (!mandatoryFor && !visibilitySelector) {
				return false;
			}

			let remove = false;

			if (mandatoryFor) {
				const { criterionKey, values } = mandatoryFor;
				remove = !values.some((key) => input.get(criterionKey) === key);
			}

			if (visibilitySelector) {
				const { criterionKey, visibleOn } = visibilitySelector;
				remove = !visibleOn.some(
					(key) => input.get(criterionKey) === key,
				);
			}

			return remove;
		})
		.map((column) => column.key);
};

export const validateColumnInput = (viewColumns, columns, input) => {
	const columnsInputIsString = typeof viewColumns === "string";

	const columnsToRender = filterColumnsToRender(columns, input).toJS();
	const mandatoryColumns = getMandatoryColumns(columnsToRender, input);
	const columnsToRemoveFromInput = getColumnsToRemoveFromInput(
		columns,
		input,
	);

	let columnsInput = columnsInputIsString
		? viewColumns.split(",")
		: viewColumns?.toJS();

	mandatoryColumns.forEach((column) => {
		if (columnsInput.includes(column)) {
			return;
		}

		return columnsInput.push(column);
	});

	columnsInput = columnsInput.filter(
		(column) => !columnsToRemoveFromInput.includes(column),
	);

	return columnsInputIsString
		? columnsInput.join(",")
		: Immutable.Set(columnsInput);
};

export const getValueSelectors = (columns) => {
	return {
		...columns.reduce((obj, item) => {
			obj[item.key] = item.valueSelector;
			return obj;
		}, {}),
	};
};

export const getColumnClasses = (columns) => {
	return {
		...columns.reduce((obj, item) => {
			obj[item.key] = item.classes;
			return obj;
		}, {}),
	};
};
