import clsx from "clsx";
import React, { useState } from "react";
import { TextInput } from "../../../search/criteria/TextInput";
import { searchInputType } from "../../types";

export function SearchInput({ searchInput }) {
	if (!searchInput) {
		return null;
	}

	const [error, setError] = useState("");
	const { id, label, placeholder, errorMessage, itemsLength, onInputChange } =
		searchInput;

	const handleChange = (e) => {
		const input = e.target.value;
		setError("");

		if (input && itemsLength === 0) {
			setError(errorMessage);
		}

		onInputChange(input);
	};

	return (
		<div className={clsx("modal__search", { error })}>
			<TextInput
				id={id}
				name={id}
				placeholder={placeholder}
				label={label}
				error={error}
				onChange={handleChange}
			/>
		</div>
	);
}

SearchInput.propTypes = {
	searchInput: searchInputType,
};
