import React, { useState } from "react";
import { InputSearch } from "../../../common/components/modals/InputSearch";
import { Overlay } from "../../../common/components/modals/Overlay";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import { useLocalSearchView } from "../../../common/useLocalSearchView";
import useSelectAll from "../../criteria/components/useSelectAll";
import useSearchState from "../../useSearchState";
import { visibleOnUserInput } from "../../utils";
import { ColumnsGroup } from "./ColumnsGroup";
import { EditViewActions } from "./EditViewActions";
import useColumnsState from "./useColumnsState";
import {
	columnsByGroups,
	filterColumnsToRender,
	getMandatoryColumns,
	getOrderedColumns,
	validateColumnInput,
} from "./utils";

export function EditView({ namespace }) {
	const { columns, columnsInput, onChangeColumns, onColumnsHaveChanged } =
		useColumnsState();
	const { currentInput } = useSearchState([""]);
	const { clearSearchView } = useLocalSearchView(namespace);
	const { clearModals } = useModal();

	const columnsToRender = filterColumnsToRender(columns, currentInput).toJS();
	const orderedColumns = getOrderedColumns(columnsToRender);
	const mandatoryColumns = getMandatoryColumns(columnsToRender, currentInput);
	const validatedColumns = validateColumnInput(
		columnsInput,
		columns,
		currentInput,
	);

	const [visibleColumns, setVisibleColumns] = useState(columnsToRender);
	const [selection, setSelection] = useState(validatedColumns);

	const onInputChange = (input) => {
		onColumnsHaveChanged();
		setSelection(input);
	};

	const { allChecked, onSelectAll } = useSelectAll(
		visibleColumns,
		selection,
		onInputChange,
		mandatoryColumns,
	);

	const newColumnsInput = orderedColumns
		.keySeq()
		.filter((key) => selection.contains(key))
		.join(",");

	const handleInvertClick = () => {
		const newSelection = orderedColumns
			.keySeq()
			.filter(
				(key) =>
					!selection.contains(key) || mandatoryColumns.includes(key),
			)
			.toSet();

		onColumnsHaveChanged();
		setSelection(newSelection);
	};

	const handleDefaultClick = () => {
		clearSearchView();
		onChangeColumns(undefined);
		onColumnsHaveChanged();
		clearModals();
	};

	const handleSaveClick = () => {
		onChangeColumns(newColumnsInput);
		clearModals();
	};

	const handleSearchInputChange = (input) => {
		const visible = columnsToRender.filter((col) =>
			visibleOnUserInput(col.label, input),
		);
		setVisibleColumns(visible);
	};

	const groups = columnsByGroups(visibleColumns).map((columns) => {
		return (
			<ColumnsGroup
				key={columns[0].group.id}
				columns={columns}
				mandatoryColumns={mandatoryColumns}
				onInputChange={onInputChange}
				selection={selection}
			/>
		);
	});

	return (
		<Overlay
			additionalClasses="edit-view-overlay"
			title="Edit columns"
			searchInput={InputSearch.add(
				"search-columns",
				"Column name",
				"Enter column name",
				"No columns found.",
				visibleColumns.length,
				handleSearchInputChange,
			)}
		>
			<div className="modal__content">{groups}</div>

			<footer className="modal__actions">
				<div className="actions__container">
					<div className="actions__group">
						<EditViewActions
							namespace={namespace}
							columnsInput={newColumnsInput}
						/>

						<div className="columns__select-all checkbox-input">
							<input
								type="checkbox"
								id="select-all-columns"
								name="select-all-columns"
								checked={allChecked}
								onChange={(e) => onSelectAll(e.target.checked)}
							/>
							<label htmlFor="select-all-columns">
								Select all
							</label>
						</div>
					</div>

					<div className="actions__group">
						<button
							className="btn btn--tertiary btn--divided btn--invert-selection btn--divided"
							onClick={handleInvertClick}
						>
							Invert selection
						</button>

						<button
							className="btn btn--tertiary btn--divided"
							onClick={handleDefaultClick}
						>
							Use default
						</button>
						<button
							className="btn btn--primary"
							onClick={handleSaveClick}
						>
							Save
						</button>
					</div>
				</div>
			</footer>
		</Overlay>
	);
}

EditView.propTypes = {
	namespace: namespaceType.isRequired,
};
