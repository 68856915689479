export const MODAL_ADVANCED_SEARCH = "advancedSearch";
export const MODAL_EDIT_VIEW = "editView";
export const MODAL_CUSTOMIZE_QUICK_SEARCH = "customizeQuickSearch";
export const MODAL_LOAD_VIEW = "loadView";
export const MODAL_DELETE_VIEW = "deleteView";
export const MODAL_SAVE_VIEW = "saveView";
export const MODAL_LOAD_SEARCH = "loadSearch";
export const MODAL_DELETE_SEARCH = "deleteSearch";
export const MODAL_SAVE_SEARCH = "saveSearch";
export const MODAL_DOWNLOAD = "downloadResults";
export const MODAL_DATA_SERVICES_REQUEST = "dataServicesRequest";
export const MODAL_REQUEST_SUCCESS = "requestSentSuccessfully";
export const MODAL_ADD_TO_FAVORITES = "addToFavorites";
export const MENU_ACTIONS = "moreActionsMenu";
export const MENU_CRITERION = "CriterionMenu";
export const MENU_TABS = "tabsMenu";
export const MENU_FAVORITES_OPTIONS = "favoritesOptionsMenu";
export const MENU_DOWNLOADS_OPTIONS = "downloadsOptionsMenu";
export const MENU_FILTERS = "filtersMenu";
export const POPOVER_RESTRICTED = "popoverRestricted";
export const POPOVER_UTILISATION = "popoverUtilisation";
