import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import { requiredRolesType } from "../../../../common/types";
import { SearchCriterion } from "../common/SearchCriterion";
import Autosuggest from "./Autosuggest";

export function AutosuggestCriterion({
	label,
	requiredRoles,
	criterionKey,
	...props
}) {
	const { currentInput, onInputChange } = useFormComponentState(
		criterionKey,
		Immutable.Map(),
	);

	return (
		<SearchCriterion label={label} requiredRoles={requiredRoles}>
			<Autosuggest
				label={label}
				criterionKey={criterionKey}
				currentInput={currentInput}
				onInputChange={onInputChange}
				{...props}
			/>
		</SearchCriterion>
	);
}

AutosuggestCriterion.propTypes = {
	label: PropTypes.string.isRequired,
	requiredRoles: requiredRolesType,
	criterionKey: PropTypes.string.isRequired,
};
