import { useSelector } from "react-redux";
import { rolesDependencies } from "./dependencies";
import { getRoles, hasOneOfRoles } from "./selectors";

export default function useRolesRestrictions(requiredRoles) {
	const userRoles = useSelector(getRoles).filter((value) => value === true);
	const openForRoles = requiredRoles?.getOpenForRoles();
	const openForRelevantRoles = filterRolesOnParentRole(openForRoles);

	const hasRequiredToOpenRoles = useSelector((state) =>
		hasOneOfRoles(state, openForRelevantRoles),
	);

	const restrictOnRoles =
		!hasRequiredToOpenRoles && openForRelevantRoles.length > 0
			? openForRelevantRoles
			: [];

	function filterRolesOnParentRole(roles) {
		if (!roles) {
			return [];
		}

		if (roles.length <= 1) {
			return roles;
		}

		return roles.filter((role) => {
			let parentRole;

			for (const [key, value] of Object.entries(rolesDependencies)) {
				if (value.includes(role)) {
					parentRole = key;
				}
			}

			return userRoles.has(parentRole);
		});
	}

	return {
		hasRequiredToOpenRoles,
		restrictOnRoles,
	};
}
