import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import { requiredRolesType } from "../../../../common/types";
import { SearchCriterion } from "../common/SearchCriterion";
import { UtilisationSearchRangeComponent } from "./UtilisationSearchRangeComponent";

export const UtilisationSearchRangeCriterion = ({
	criterionKey,
	label,
	requiredRoles,
	...props
}) => {
	const { currentInput, onInputChange, onClearInput } = useFormComponentState(
		criterionKey,
		Immutable.Map(),
	);

	return (
		<SearchCriterion label={label} requiredRoles={requiredRoles}>
			<UtilisationSearchRangeComponent
				currentInput={currentInput}
				onInputChange={onInputChange}
				onClearInput={onClearInput}
				criterionKey={criterionKey}
				label={label}
				{...props}
			/>
		</SearchCriterion>
	);
};

UtilisationSearchRangeCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	requiredRoles: requiredRolesType,
};
