export const useLocalSearchView = (namespace) => {
	const storageKey = generateStorageViewKey(namespace);

	const clearSearchView = () => localStorage.removeItem(storageKey);

	const storeSearchView = (viewParameters) => {
		localStorage.setItem(storageKey, JSON.stringify(viewParameters));
	};

	const getStoredSearchView = () => {
		return JSON.parse(localStorage.getItem(storageKey));
	};

	return {
		getStoredSearchView,
		storeSearchView,
		clearSearchView,
	};
};

export const generateStorageViewKey = (namespace) =>
	`search_view_data-${namespace}`;
