import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import { useDropdown } from "../../../../common/components/dropdown/useDropdown";
import { Loader } from "../../../../common/components/Loader";
import useModal from "../../../../common/components/modals/useModal";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import { checkboxesType, criterionLinkType } from "../../../../common/types";
import { visibleOnUserInput } from "../../../utils";
import { getCriterionModalId } from "../../utils";
import useValidation from "../../validation/useValidation";
import ChoicesCounter from "../common/ChoicesCounter";
import CriterionFooter from "../common/CriterionFooter";
import { CriterionLabel } from "../common/CriterionLabel";
import CriterionLink from "../common/CriterionLink";
import CriterionSearchInput from "../common/CriterionSearchInput";
import useDropdownPosition from "../useDropdownPosition";
import Checkboxes from "./Checkboxes";

export default function CheckboxesDropdown({
	choices,
	closeOnClickOutside,
	currentInput,
	criterionKey,
	error,
	label,
	loading,
	link,
	onInputChange,
	onClearInput,
	prefix = null,
	quickSearch = false,
	...props
}) {
	const [value, setValue] = useState("");
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } =
		useDropdown(closeOnClickOutside);
	const { valid } = useValidation(criterionKey);
	const visibleChoices = choices.filter((choice) =>
		visibleOnUserInput(choice.value, value),
	);
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
	);
	const modalId = getCriterionModalId(criterionKey);

	const scrollable = choices && choices.length > 6;
	const dropdownClass = clsx("criterion", "dropdown", "checkbox-dropdown", {
		error,
		loading,
		"dropdown--scrollable": scrollable,
		required: !valid,
		"with-link": link,
	});

	if (!dropdownIsOpened && value) {
		setValue("");
	}

	const handleDropdownToggleClick = () => {
		onDropdownToggle();
		onOpenModal(modalId);
	};

	const handleClearInput = () => {
		onClearInput();
		setValue("");
		onDropdownToggle();
		onCloseModal(modalId);
	};

	const handleSaveInput = () => {
		onDropdownToggle();
		onCloseModal(modalId);
	};

	let content;

	if (error) {
		content = <p className="error-text">{error}</p>;
	} else {
		content = (
			<>
				<CriterionSearchInput
					isVisible={scrollable}
					value={value}
					criterionKey={criterionKey}
					dropdownIsOpened={dropdownIsOpened}
					isMobile={isMobile}
					onInputChange={(e) => setValue(e.target.value)}
				/>

				<div className="dropdown-items-wrapper">
					<Checkboxes
						label={label}
						choices={visibleChoices}
						criterionKey={criterionKey}
						currentInput={currentInput}
						quickSearch={quickSearch}
						scrollable={scrollable}
						onInputChange={onInputChange}
						{...props}
					/>
				</div>

				<CriterionFooter
					onSaveInput={handleSaveInput}
					onClearInput={handleClearInput}
				/>
			</>
		);
	}

	return (
		<div
			className={dropdownClass}
			ref={dropdownRef}
			data-criterion={true}
			tabIndex={-1}
		>
			<button
				type="button"
				onClick={handleDropdownToggleClick}
				disabled={loading}
				className={clsx("dropdown-toggle", {
					"choices-visible": currentInput.size > 0,
				})}
				data-criterion={true}
				aria-expanded={dropdownIsOpened}
			>
				<CriterionLabel label={label} prefix={prefix} />
				<CriterionLink link={link} />
				{loading && <Loader />}
			</button>

			<ChoicesCounter currentInput={currentInput} />

			<ConditionalWrapper
				condition={isMobile}
				wrapper={(children) => (
					<ActionsMenu
						id={modalId}
						title={label}
						onClose={handleSaveInput}
					>
						{children}
					</ActionsMenu>
				)}
			>
				<div
					className={clsx("criterion-dropdown-menu dropdown-menu", {
						"dropdown-menu--opened": dropdownIsOpened,
						"dropdown-menu--inverted-y": isDropdownBelowViewport,
						"dropdown-menu--hidden": isDropdownHidden,
					})}
				>
					{content}
				</div>
			</ConditionalWrapper>
		</div>
	);
}

CheckboxesDropdown.propTypes = {
	choices: PropTypes.array.isRequired,
	closeOnClickOutside: PropTypes.bool,
	criterionKey: PropTypes.string.isRequired,
	currentInput: checkboxesType.isRequired,
	error: PropTypes.string,
	label: PropTypes.string.isRequired,
	link: criterionLinkType,
	loading: PropTypes.bool,
	onClearInput: PropTypes.func.isRequired,
	onInputChange: PropTypes.func.isRequired,
	quickSearch: PropTypes.bool,
	prefix: PropTypes.string,
};
