import React from "react";
import { MODAL_DOWNLOAD } from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";
import useRolesVisibility from "../../common/roles/useRolesVisibility";
import { namespaceType, requiredRolesType } from "../../common/types";
import useSearchState from "../useSearchState";
import { getDownloadFullUrl } from "./api";
import { DownloadDropdown } from "./DownloadDropdown";
import useDownload from "./useDownload";

export function Download({ namespace, downloadRoles }) {
	const { selectFormat } = useDownload();
	const { hasRequiredRoles } = useRolesVisibility(downloadRoles);
	const { hashData } = useSearchState([""]);
	const { onOpenModal } = useModal();

	const formats = [
		{
			id: "xlsx",
			type: "xlsx",
			label: ".xls file",
			label_modal: ".xls",
		},
		{
			id: "csv-semicolon",
			type: "csv",
			label: ".csv (semicolon)",
			label_modal: ".csv (semicolon)",
			options: {
				csv_delimiter: ";",
			},
		},
		{
			id: "csv-comma",
			type: "csv",
			label: ".csv (comma)",
			label_modal: ".csv (comma)",
			options: {
				csv_delimiter: ",",
			},
		},
		{
			id: "json",
			type: "json",
			label: ".json file",
			label_modal: ".json",
		},
	];

	const getDownloadUrl = (format, extraParameters) =>
		getDownloadFullUrl(namespace, format, hashData, extraParameters);

	const handleDropdownItemClick = (id) => {
		const format = formats.find((format) => format.id === id);

		selectFormat(format);
		onOpenModal(MODAL_DOWNLOAD);
	};

	return (
		<DownloadDropdown
			formats={formats}
			hasRequiredRoles={hasRequiredRoles}
			getDownloadUrl={getDownloadUrl}
			onDropdownItemClick={handleDropdownItemClick}
		/>
	);
}

Download.propTypes = {
	namespace: namespaceType.isRequired,
	downloadRoles: requiredRolesType,
};
