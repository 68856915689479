import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenuButton } from "../../../search/actions/ActionsMenuButton";
import { childrenType } from "../../types";
import { Logo } from "../Logo";
import useEscapeKey from "./useEscapeKey";
import useModal from "./useModal";

export function Modal({ title, alignContent, additionalClasses, children }) {
	const { openedModalsExist, onCloseModal } = useModal();
	useEscapeKey(onCloseModal);

	const classes = clsx("modal", additionalClasses, {
		open: openedModalsExist,
	});

	return (
		<div role="dialog" aria-labelledby="modalTitle" className={classes}>
			<div
				className={clsx("modal__container", alignContent)}
				onClick={(e) => e.stopPropagation()}
			>
				<header className="modal__header">
					<Logo />
					<ActionsMenuButton
						onClick={() => onCloseModal()}
						type="btn--back"
						name="Back"
					/>
					<h2 id="modalTitle" className="modal__title">
						{title}
					</h2>
					<button
						type="button"
						className="btn--close"
						onClick={() => onCloseModal()}
					></button>
				</header>

				{children}
			</div>
		</div>
	);
}

Modal.propTypes = {
	additionalClasses: PropTypes.string,
	alignContent: PropTypes.string,
	children: childrenType.isRequired,
	title: PropTypes.string.isRequired,
};
