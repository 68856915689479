// FIXME: Remove after CHA-6308 for criteria to use default getSuggestions
export const getAirportSuggestions = (options, value) => {
    if (!value)
        {return [];}

    const inputValue = value.trim().toLowerCase();
    const inputCode = value.toUpperCase();
    return inputValue.length === 0 ? [] : options.valueSeq()
        .sortBy(option => {
            if (option.IATA === inputCode) {
                return '0';
            }
            if (option.ICAO === inputCode) {
                return '1';
            }
            return option.name;
        })
        .take(20)
        .toArray();
};