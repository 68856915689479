import clsx from "clsx";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import useFetchSuggestions from "../../../../common/choices/useFetchSuggestions";
import useNamesForCodes from "../../../../common/choices/useNamesForCodes";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import useModal from "../../../../common/components/modals/useModal";
import { useCloseOnClickOutside } from "../../../../common/components/useCloseOnClickOutside";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import {
	autoSuggestType,
	currentInputAutoSuggestType,
} from "../../../../common/types";
import { useBlockPreview } from "../../../searches/useBlockPreview";
import { isEmptyObject } from "../../../utils";
import { getSelectedChoices } from "../../choices/choices";
import { TextInput } from "../../TextInput";
import { getCriterionModalId, getPrefixedChoiceLabel } from "../../utils";
import useValidation from "../../validation/useValidation";
import { SetFetchedChoicesInitializer } from "../SetFetchedChoicesInitializer";
import { getSuggestions } from "./getSuggestions";
import { SelectedSuggestions } from "./SelectedSuggestions";
import Suggestions from "./Suggestions";

export default function Autosuggest({
	label,
	placeholder,
	criterionKey,
	currentInput,
	onInputChange,
	endpoint,
	prefix,
	quickSearch,
	showSelectedChoices = false,
	getSuggestions,
	disableMobile = false,
}) {
	const value = currentInput.get("free_text", "");
	const codes = currentInput.get("codes", Immutable.List());
	const codeFetchKey = "code";
	const codeFetchParameter = "codes";
	const { nameIndex } = useNamesForCodes(
		endpoint,
		codeFetchKey,
		codeFetchParameter,
		codes,
	);
	const { valid, message, disabled } = useValidation(criterionKey);
	let { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const modalId = getCriterionModalId(criterionKey);

	const [dropdownIsOpened, setDropdownIsOpened] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [fetchParams, setFetchParams] = useState({});
	const [cursor, setCursor] = useState(-1);
	const [focusInput, setFocusInput] = useState(false);

	const { blockPreview, allowPreview } = useBlockPreview();

	const highlightedItem = suggestions[cursor]?.code;
	const ref = useRef(null);
	useCloseOnClickOutside(ref, dropdownIsOpened, () =>
		setDropdownIsOpened(!dropdownIsOpened),
	);

	if (disableMobile) {
		isMobile = false;
	}

	const shouldFetch = !isEmptyObject(fetchParams) && value.length > 1;

	const { choices, loading, error } = useFetchSuggestions(
		endpoint,
		fetchParams,
		shouldFetch,
		500,
	);

	useEffect(() => {
		if (!loading && !error && choices && value.length > 1) {
			const options = choices
				? Immutable.OrderedMap(choices.map((v) => [v[codeFetchKey], v]))
				: Immutable.OrderedMap();
			setSuggestions(
				getSuggestions(options, value).map((item) => ({
					...item,
					disabled: codes.includes(item.code),
				})),
			);
		}
	}, [loading, error, choices, value]);

	const handleChange = (value) => {
		const newInput = currentInput.set("free_text", value);
		const params = {
			[codeFetchParameter]: [],
			query: value,
		};
		setFetchParams(params);
		onInputChange(newInput);
		setDropdownIsOpened(true);

		if (!value) {
			setSuggestions([]);
		}
	};

	const handleSuggestionSelected = (key) => {
		if (codes.includes(key)) {
			return;
		}
		const newCodes = codes.push(key);

		const newInput = currentInput
			.set("codes", newCodes)
			.set("free_text", "");

		onInputChange(newInput);
		setDropdownIsOpened(false);
		setCursor(-1);
		setFocusInput(true);
		setSuggestions([]);
		onCloseModal(modalId);
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 38 && cursor > 0) {
			setCursor(cursor - 1);
		} else if (e.keyCode === 40 && cursor < suggestions.length - 1) {
			setCursor(cursor + 1);
		}

		if (e.keyCode === 13) {
			setDropdownIsOpened(!dropdownIsOpened);

			if (highlightedItem) {
				handleSuggestionSelected(highlightedItem);
			}
		}
	};

	const handleMobileDropdownToggleClick = () => {
		onOpenModal(modalId);
		setDropdownIsOpened(true);
		blockPreview();
	};

	const handleDropdownToggleClick = () => {
		setDropdownIsOpened(true);
		blockPreview();
	};

	const handleInputBlur = () => {
		allowPreview();
	};

	return (
		<div
			ref={ref}
			className={clsx("criterion input-field autosuggest-container", {
				error: !valid,
				disabled,
			})}
		>
			<SetFetchedChoicesInitializer
				criterionKey={criterionKey}
				criterionLabel={getPrefixedChoiceLabel(label, prefix)}
				criterionChoices={getSelectedChoices(value, codes, nameIndex)}
			/>
			{isMobile && (
				<TextInput
					id={`${criterionKey}${quickSearch ? "_quick_search" : ""}-placeholder`}
					autoFocus={!isMobile && focusInput}
					name={`${criterionKey}-placeholder`}
					value={value}
					label={label}
					prefix={prefix}
					error={message}
					disabled={disabled}
					placeholder={placeholder}
					onChange={(e) => handleChange(e.target.value)}
					onKeyDown={handleKeyDown}
					onFocus={handleMobileDropdownToggleClick}
					onBlur={handleInputBlur}
				/>
			)}
			<ConditionalWrapper
				condition={isMobile}
				wrapper={(children) => (
					<ActionsMenu
						id={modalId}
						title={label}
						onClose={onCloseModal}
					>
						{children}
					</ActionsMenu>
				)}
			>
				<ConditionalWrapper
					condition={isMobile}
					wrapper={(children) => (
						<div className="autosuggest__mobile-overlay">
							{children}
						</div>
					)}
				>
					<TextInput
						id={`${criterionKey}${quickSearch ? "_quick_search" : ""}`}
						autoFocus={!isMobile && focusInput}
						name={criterionKey}
						value={value}
						label={label}
						prefix={prefix}
						error={message}
						disabled={disabled}
						placeholder={placeholder}
						onChange={(e) => handleChange(e.target.value)}
						onKeyDown={handleKeyDown}
						onFocus={handleDropdownToggleClick}
						onBlur={handleInputBlur}
					/>
				</ConditionalWrapper>
				<Suggestions
					value={value}
					suggestions={suggestions}
					dropdownIsOpened={dropdownIsOpened}
					codeFetchKey={codeFetchKey}
					onSuggestionSelected={handleSuggestionSelected}
					dropdownRef={ref}
					quickSearch={quickSearch}
					active={highlightedItem}
					loading={loading}
				/>
			</ConditionalWrapper>

			{showSelectedChoices && (
				<SelectedSuggestions
					codes={codes}
					currentInput={currentInput}
					nameIndex={nameIndex}
					onInputChange={onInputChange}
				/>
			)}
		</div>
	);
}

Autosuggest.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	criterionKey: PropTypes.string.isRequired,
	currentInput: currentInputAutoSuggestType.isRequired,
	onInputChange: PropTypes.func.isRequired,
	endpoint: autoSuggestType.isRequired,
	prefix: PropTypes.string,
	showSelectedChoices: PropTypes.bool,
	getSuggestions: PropTypes.func,
	quickSearch: PropTypes.bool,
	disableMobile: PropTypes.bool,
};

Autosuggest.defaultProps = {
	getSuggestions,
};
