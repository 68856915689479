import Immutable from "immutable";
import queryString from "query-string";

export function determineCurrentHash() {
	// For convenience, remove the leading hash character so reducers don't have to deal with it.
	return location.hash ? location.hash.substring(1) : "";
}

export const getHashState = (initialHash, defaultInput) => {
	const initialHashData = interceptInvalidInput(decodeHash(initialHash));

	if (initialHashData) {
		const hashData = Immutable.fromJS(initialHashData);
		const hashInput = hashData.get("input");
		const hashInputWithDefaultInput = !defaultInput.isEmpty()
			? defaultInput.merge(hashInput)
			: hashInput;

		return hashData.set("input", hashInputWithDefaultInput);
	}

	return Immutable.Map();
};

export function getInitialSearchState(defaultInput = Immutable.Map()) {
	const initialHash = determineCurrentHash();
	const initialHashState = getHashState(initialHash, defaultInput);
	const hashInput = initialHashState.get("input", Immutable.Map());
	const initialInputState = hashInput.isEmpty() ? defaultInput : hashInput;

	return {
		initialHashState,
		initialInputState,
	};
}

export function flattenInput(inputs) {
	return Object.keys(inputs).reduce((params, key) => {
		const input = inputs[key];
		if (!Array.isArray(input) && typeof input === "object") {
			Immutable.Map(input)
				.filter((value) => !!value)
				.forEach((v, k) => (params[`${key}_${k}`] = v));
		} else {
			params[key] = input;
		}
		return params;
	}, {});
}

export function flattenHashData(hashData) {
	hashData = hashData.toJS();
	const inputs = hashData.input || {};
	const flattenedInput = flattenInput(inputs);

	return Object.assign(flattenedInput, hashData.parameters);
}

export function encodeHash(data) {
	const json = JSON.stringify(data, hashJSONReplacer);

	if (json === "{}") {
		return "";
	}

	const encoded = btoa(json);

	return queryString.stringify({
		search: encoded,
	});
}

export function decodeHash(hash) {
	if (!hash || hash.length < 3) {
		return null;
	}

	const params = queryString.parse(hash).search;

	if (params && isValidEncoding(params)) {
		const decoded = atob(params);
		try {
			return JSON.parse(decoded, hashJSONReviver);
		} catch (ex) {
			console.error(ex);
		}
	}

	return null;
}

export function clearHash() {
	window.location.hash = "";
}

export function isValidEncoding(params) {
	const base64regex =
		/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

	return base64regex.test(params);
}

export const createSearchLink = (searchPath, input) => {
	const defaultInput = {
		input: input || Immutable.Map(),
		parameters: {
			salt: Math.random(),
		},
	};
	const defaultHash = encodeHash(defaultInput);
	return searchPath + "#" + defaultHash;
};

const hashJSONReplacer = (key, value) =>
	key === "free_text" ? encodeURIComponent(value) : value;
const hashJSONReviver = (key, value) =>
	key === "free_text" ? decodeURIComponent(value) : value;

const interceptInvalidInput = (hashData) => {
	if (!hashData?.input) {
		return hashData;
	}

	const criteriaToCheck = ["airline_display_name"];
	let input = {};

	for (const [key, value] of Object.entries(hashData.input)) {
		if (!criteriaToCheck.includes(key)) {
			input[key] = value;
			continue;
		}
		if (key === "airline_display_name" && typeof value === "string") {
			input[key] = { free_text: value, codes: [] };
			continue;
		}
		input[key] = value;
	}

	return { ...hashData, input };
};
