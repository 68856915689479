import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import {
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { useClientState } from "../../../../common/useClientState";
import { SearchCriterion } from "../common/SearchCriterion";
import CheckboxesDropdown from "./CheckboxesDropdown";

export function CheckboxCriterion({
	choices,
	criterionKey,
	label,
	prefix,
	requiredRoles,
	visibilitySelector,
	saveToClientState,
	...props
}) {
	const { currentInput, onInputChange, onClearInput } = useFormComponentState(
		criterionKey,
		Immutable.List(),
	);
	const { onChangeClientState } = useClientState(
		criterionKey,
		saveToClientState,
	);

	return (
		<SearchCriterion
			label={label}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<CheckboxesDropdown
				label={label}
				choices={choices}
				criterionKey={criterionKey}
				currentInput={currentInput}
				onInputChange={onInputChange}
				onClearInput={onClearInput}
				onChangeClientState={onChangeClientState}
				prefix={prefix}
				{...props}
			/>
		</SearchCriterion>
	);
}

CheckboxCriterion.propTypes = {
	choices: PropTypes.array.isRequired,
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	prefix: PropTypes.string,
	requiredRoles: requiredRolesType,
	saveToClientState: PropTypes.bool,
	visibilitySelector: visibilitySelectorType,
};
